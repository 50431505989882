import React from "react";
import BusinessCategoryComponent from "../../components/cards/onboardingCard/business-category";
import "./style.scss";


const BusinessCategory = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <BusinessCategoryComponent />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BusinessCategory;
