import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import Toaster from "../../../common/Toaster";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import { brandAge as brandAgeConstant } from "../../../../util/constants";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";

const BusinessCategoryComponent = ({ updateBrand, age, brandId }) => {
 
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});
  const [brandAge, setBrandAge] = useState("");
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const options = ["Brand", "Boutique", "Corporate"];

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  useEffect(() => {

    const localstorageUser = JSON.parse(localStorage.getItem("registeringUser"));
    setLocalstorageUserState(localstorageUser);
    setBrandAge(
      localstorageUser && localstorageUser.businessCategory ? localstorageUser.businessCategory : ""
      )

    if (!localstorageUser) {
     
      if(!localstorageUser || !localstorageUser.name){
        navigate("/onboarding/step-one");
      }
      else  if(!localstorageUser || !localstorageUser.state){
        navigateBack();
      }
     
    }
  }, []);

  const onSubmit = async () => {
    if (!brandAge) {
      setToastrMsg("Please choose!");
      doRefresh((prev) => prev + 1);
      return;
    }
    localstorageUserState.businessCategory = brandAge;
    localStorage.setItem(
      "registeringUser",
      JSON.stringify(localstorageUserState)
    );
    var updateBrandResonse = await updateBrand(brandId, { businessCategory: brandAge });
    if (updateBrandResonse && !updateBrandResonse.error) {
      navigate("/onboarding/business-type");
      // navigate("/onboarding/brand-type");
    } else {
      setToastrMsg("Unable to add Business type");
      doRefresh((prev) => prev + 1);
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/step-one");
  };

  const setBrandAgeHandler = (type, value) => {
    if (type === "radio") {
      for (let ba of brandAge) {
        if (!options.includes(ba)) {
          setBrandAge("");
        }
      }
      if (brandAge.includes(value)) {
        setBrandAge("");
      } else {
        setBrandAge(value);
      }
    } else {
      setBrandAge(value);
    }
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row age">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img left-flex">
            {/* {isMobile} */}
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            <Title className="hide-mobile" text="Business type?" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading hide-desktop">
            <Title text="Business type?" />
          </div>

          <div className="row option-spacing mob-spacing">
            <div className="col-6 col-sm-4 mid-option">
              <div
              className={`option-card option-med`}
              style={
                brandAge === 'Brand'
                  ? { background: "#31ffea" }
                  : null
              }
              name="Brand"
              onClick={() =>
                setBrandAgeHandler("radio",'Brand')
              }
              >
                Brand
                {/* <span className="opt-info">Less then 1yr</span> */}
              </div>
              
            </div>
            <div className="col-6 col-sm-4 mid-option hide">
              {/* <OptionCard
                style={
                  brandAge === "Emerging"
                    ? { background: "#31ffea" }
                    : null
                }
                name="Emerging"
                type="medium"
                onClick={() =>
                  setBrandAge("Emerging")
                }
              /> */}
              <div
              className={`option-card option-med`}
              style={
                brandAge === 'Accessory'
                  ? { background: "#31ffea" }
                  : null
              }
              name="Accessory"
              onClick={() =>
                setBrandAge('Accessory')
              }
              >
                Accessory
                {/* <span className="opt-info">2+ yrs</span> */}
              </div>
            </div>

            <div className="col-6 col-sm-4 mid-option">
              <OptionCard
                style={
                  brandAge === "Boutique"
                    ? { background: "#31ffea" }
                    : null
                }
                name="Boutique"
                type="medium"
                onClick={() => setBrandAgeHandler("radio","Boutique")}
              />
            </div>
          </div>
          <div className="row option-spacing mob-spacing">
           
            <div className="col-6 col-sm-4 mid-option">
              <OptionCard
                style={
                  brandAge === "Corporate"
                    ? { background: "#31ffea" }
                    : null
                }
                name="Corporate"
                type="medium"
                onClick={() =>
                  setBrandAgeHandler("radio","Corporate")
                }
              />
            </div>
            <div className="col-6 col-sm-4 mid-option input-align">
            <input
                type="text"
                className="pi-type-own custom-pi"
                placeholder="If other, type here "
                onChange={(e) => setBrandAgeHandler("text", e.target.value)}
                value={brandAge && !options.includes(brandAge) ? brandAge : ""}
              />
              </div>
          </div>
     
        <div className={!isMobile ? "row" : ""}>
          {/* <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div> */}
          <div className="col-12 margin-mobile to-left">
            <div className="nav-conatiner">
              <div>
                <span
                  className="text-decoration-none"
                  onClick={() => navigateBack()}
                >
                  <NavLeft />
                  <span className="nav-text">Back</span>
                </span>
              </div>
              <div>
                <span
                  className="text-decoration-none"
                  onClick={() => onSubmit()}
                >
                  <span className="nav-text">Next</span>
                  <NavRight />
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  age: brand.age,
  brandId: brand.brandId,
});

export default connect(mapStateToProps, { updateBrand })(BusinessCategoryComponent);
